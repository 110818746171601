import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { IoIosArrowRoundForward } from 'react-icons/io'

const jobListings = [
  // {
  //   title_name: 'Senior Engineer',
  //   location: 'Brooklyn, NY',
  //   link: 'https://www.linkedin.com/jobs/view/1004856060/',
  // }
]

const Jobs = () => (
  <section id="jobs">
    <Container>
      <Row>
        <Col lg={{ size: 11, offset: 1 }}>
          <h2> Work with us </h2>
        </Col>
        <Col lg={{ size: 6, offset: 1 }}>
          <p>
            We’re looking for candidates who can help us further our vision and
            enrich our culture of scientific inquiry, enthusiasm for urbanism,
            and respect. We welcome applicants from all walks of life and strive
            to create a diverse workplace where all viewpoints are welcome.
          </p>
          <p>
            Topos is based in New York and is comprised of a team encompassing
            diverse skill sets and of widespread origins: London, Istanbul,
            Seoul, Mexico City, New Orleans, Sydney, Hawaii, Berkeley, New York,
            Vermont.
          </p>
          <a className="link" href="mailto:info@topos.ai">
            <IoIosArrowRoundForward /> Get in touch{' '}
          </a>
        </Col>
      </Row>
      {jobListings.length > 0 && (
        <Row>
          <Col lg={{ size: 11, offset: 1 }}>
            <h3> Open Positions </h3>
          </Col>
          <Col xs={6} sm={4} lg={{ size: 4, offset: 1 }}>
            {jobListings.map(({ title, location, link }) => (
              <div key={title} className="job-posting">
                <a href={link}> {title} </a>
                <p> {location} </p>
              </div>
            ))}
          </Col>
        </Row>
      )}
      {/*<Row>
        <Col lg={{size: 10, offset: 1}}>
          <img src={image} alt="topos-team-at-brooklyn-holiday-party"/>
        </Col>
        </Row>*/}
    </Container>
  </section>
)

export default Jobs
