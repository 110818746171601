import React from 'react'

import Layout from '../components/layout'
import Title from '../sections/title'
import About from '../sections/about'
import Blog from '../sections/blog'
import Jobs from '../sections/jobs'
import Products from '../sections/products'
import SEO from '../services/seo'

const IndexPage = ({ pageContext }) => {

  return (
    <Layout scrollToSection={!!pageContext.scrollToSection && pageContext.scrollToSection}>
      <SEO
        title="Retail site selection and revenue predictions | Topos"
        keywords={[
          'topos',
          'topos.ai',
          'location intelligence',
          'location',
          'artificial intelligence',
          'ai',
          'ml',
          'urbanism',
          'cities',
          'smartcity',
          'data science',
          'machine learning',
          'data',
          'data-driven',
          'neighborhoods',
          'micro-neighborhoods',
        ]}
        siteUrl="https://topos.com"
        description="Retail site selection and revenue predictions, powered by hundreds of data sources and custom-built Artificial Intelligence"
      />
      <Title />
      <About />
      <Products />
      <Jobs />
      <Blog />
    </Layout>
  )
}

export default IndexPage
