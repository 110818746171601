import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import FitImg from '../images/fit.png'
import PerformanceImg from '../images/performance.png'
import { IoIosArrowRoundForward } from 'react-icons/io'

const Products = () => (
  <section id="products">
    <Container>
      <Row>
        <Col sm={12} lg={{ size: 5, offset: 1 }}>
          <h2> Our Products </h2>
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={{ size: 5, offset: 1 }}>
          <p>
            Retail site selection and revenue predictions, powered by hundreds of data sources and advanced Artificial Intelligence 
          </p>
        </Col>
      </Row>
      <Row className="product" style={{marginBottom: 80}}>
        <Col sm={12} md={6} lg={{ size: 5, offset: 1 }}>
          <img src={FitImg} alt="Topos Similarity Product" />
        </Col>
        <Col sm={12} md={5} lg={4} className="product-text">
          <h3>Quick, clear site selection guidance</h3>
            <ul>
              <li>No pre-work required, just enter an address to see extensive tenant matches or a brand to see nationwide expansion guidance</li>
              <li>Replaces multiple tools: map-based visuals, comprehensive data insights and clearly justified recommendations, brand contact info</li>
            </ul>
          <a className="link" href="mailto:info@topos.ai">
            <IoIosArrowRoundForward /> Request a demo
          </a>
        </Col>
      </Row>
      <Row className="product">
        <Col sm={12} md={6} lg={{ size: 5, offset: 1 }}>
          <img src={PerformanceImg} alt="Topos Similarity Product" />
        </Col>
        <Col sm={12} md={5} lg={4} className="product-text">
          <h3>Accurate revenue predictions with ease</h3>
            <ul>
              <li>By ingesting just a few data points per site and with as few as ten existing locations, see refined site selection guidance and revenue predictions</li>
              <li>Identify the best areas to target for expansion, whether a nationwide market ranking or zooming in on a single market</li>
            </ul>
          <a className="link" href="mailto:info@topos.ai">
            <IoIosArrowRoundForward /> If you have 10+ locations, let’s discuss a free trial
          </a>
        </Col>
      </Row>

      {/* <a href="mailto:info@topos.ai">connect</a> */}

      {/* <Col sm={12} lg={{ size: 5, offset: 1, order: 1 }}> */}
      {/*   <img src={similaritiesImg} alt="Topos Similarity Product" /> */}
      {/* </Col> */}
    </Container>
  </section>
)

export default Products
