import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Signup from '../components/signup'

const Title = () => (
  <section id="title">
    <Container>
      <Row>
        <Col sm={12} md={9} lg={{ size: 6, offset: 1 }}>
          <h1> Transforming the way we understand cities </h1>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={8} lg={{ size: 5, offset: 1 }}>
          <p className="subtitle">
            {' '}
            We use Artificial Intelligence to uncover the nuanced underpinnings
            of urban culture and the interaction between people and place.{' '}
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={10} lg={{ size: 6, offset: 1 }}>
          <Signup />
        </Col>
      </Row>
    </Container>
  </section>
)

export default Title
