import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'reactstrap'
import Typist from 'react-typist'
import 'react-typist/dist/Typist.css'
import { IoIosArrowRoundForward } from 'react-icons/io'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import istanbul from '../images/street-istanbul.png'
import lasvegas from '../images/street-lasvegas.png'
import losangeles from '../images/street-losangeles.png'
// import mexicocity from '../images/street-mexicocity.png'
// import mumbai from '../images/street-mumbai.png'
import newdelhi from '../images/street-newdelhi.png'
import nyc from '../images/street-nyc.png'
import saopaolo from '../images/street-saopaolo.png'
import shanghai from '../images/street-shanghai.png'
import tehran from '../images/street-tehran.png'
import tokyo from '../images/street-tokyo.png'
import withSizes from 'react-sizes'
// import vienna from '../images/street-vienna.png'
import CrossfadeImage from 'react-crossfade-image'

// const randomNoRepeats = array => {
//   let copy = array.slice(0)
//   return function() {
//     if (copy.length < 1) {
//       copy = array.slice(0)
//     }
//     const index = Math.floor(Math.random() * copy.length)
//     const item = copy[index]
//     copy.splice(index, 1)
//     return item
//   }
// }

const neighborhoods =
  //randomNoRepeats([
  [
    'Hayes Valley',
    'French Quarter',
    'Union Square',
    'Silver Lake',
    'Williamsburg',
    'Logan Square',
    'Coconut Grove',
    'Echo Park',
    'Torrey Pines',
  ] //)

const cities = [
  { name: 'Houston', src: istanbul },
  { name: 'New York City', src: nyc },
  // { name: 'Chicago', src: mexicocity },
  { name: 'Minneapolis', src: losangeles },
  { name: 'Phoenix', src: tokyo },
  { name: 'Kansas City', src: newdelhi },
  { name: 'Miami', src: lasvegas },
  { name: 'Albuquerque', src: saopaolo },
  { name: 'Atlanta', src: shanghai },
  { name: 'Washington D.C.', src: tehran },
]

const typistLines = cities.reduce((arr, { name: cityName }, i) => {
  const neighborhood = neighborhoods[i]
  arr = arr.concat(`${neighborhood} of ${cityName}?`)
  arr = arr.concat(
    <Typist.Backspace
      key={i}
      count={cityName.length + neighborhood.length + 5}
      delay={1500}
    />
  )
  return arr
}, [])

let typistText = new Array(100)
  .fill(typistLines)
  .concat('Hayes Valley of Houston?')

typistText = [
  [`What is the ${typistText[0][0]}`, ...typistText[0].slice(1)],
  ...typistText.slice(1),
]

class About extends Component {
  state = {
    image: istanbul,
  }

  onLineTyped = (line, lineIdx) => {
    if (lineIdx % 2) {
      const newIndex = ((lineIdx - 1) / 2 + 1) % cities.length
      this.setState({ image: cities[newIndex].src })
    }
  }



  render() {
    return (
      <Fragment>
        <Container id="map">
          <CrossfadeImage
            src={this.state.image}
            duration={1500}
            timingFunction="ease-in-out"
          />
        </Container> 
        <section id="about">
          <Container>
            <Row>
              <Col lg={{ size: 11, offset: 1 }}>
                <h2>
                  {!this.props.typistWrapped ? <Typist onLineTyped={this.onLineTyped}>{typistText}</Typist> : "What is the Williamsburg of Houston?"}
                </h2>
              </Col>
              <Col lg={{ size: 6, offset: 1 }}>
                <p>
                  We use advanced AI techniques to transform heterogeneous data
                  from hundreds of sources, producing a holistic understanding
                  of location that encompasses factors ranging from nightlife
                  vibrancy, to how urban an area feels, to likelihood of
                  different business types to thrive.
                </p>

                <p>
                  Using similarities between locations across neighborhoods,
                  cities and the country we’re able to answer questions such as
                  “What is the Williamsburg of Kansas City?”
                </p>

                <AnchorLink className="link" offset="50" href="#blog">
                  <IoIosArrowRoundForward /> READ MORE ON THE BLOG
                </AnchorLink>
              </Col>
            </Row>
          </Container>
        </section>
      </Fragment>
    )
  }
}

const mapSizesToProps = ({ width }) => ({
  typistWrapped: width < 815,
})

export default withSizes(mapSizesToProps)(About)
