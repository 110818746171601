import React from 'react'
import { Container, Card, Row, Col } from 'reactstrap'
import Masonry from 'react-masonry-component'

import mediumPosts from '../assets/MediumPostMeta'

const Blog = () => (
  <section id="blog">
    <Container>
      <Row>
        <Col lg={{ size: 10, offset: 1 }}>
          <h2> Blog </h2>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 10, offset: 1 }}>
          <Masonry className={'blog-grid row'}>
            {mediumPosts.map(({ id, link, title, createdAt, subtitle, imageId, readingTime }) => {

              return (
                <Col key={id} xs={12} sm={6} xl={4}>
                  <a href={link}>
                    <Card id={`id${id}`}>
                      <img
                        src={imageId}
                        alt={title}
                      />
                      <div className="card-content">
                        <div className="card-top">
                          <p> {createdAt}</p>
                          <p> {parseInt(readingTime)} Min Read </p>
                        </div>
                        <h4 className="card-title"> {title} </h4>
                        <p className="card-subtitle"> {subtitle} </p>
                      </div>
                    </Card>
                  </a>
                </Col>
              )
            })}
          </Masonry>
        </Col>
      </Row>
    </Container>
  </section>
)

export default Blog
