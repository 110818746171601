const mediumPosts = [
	{
		id: "1",
		title: "Predicting COVID-19 With Tax Returns",
		createdAt: "2020-06-10",
		link: "https://medium.com/topos-ai/predicting-covid-19-with-tax-returns-2c8cdbc2b20d?source=collection_home---4------0-----------------------",
		subtitle: "We study the relationship between fine-grained income tax data and COVID-19 cases at the zipcode level in NYC.",
		imageId: "https://cdn-images-1.medium.com/max/1200/1*pEf2oDB6sHM8k8aVir0mDg.png",
		readingTime: "7"
	},
	{
		id: "2",
		title: "Visualizing COVID-19 beyond counties",
		createdAt: "2020-06-02",
		link: "https://medium.com/topos-ai/visualizing-covid-19-beyond-counties-d1026e5c7efa",
		subtitle: "In this article we address the issue of county boundaries by breaking apart the underlying absolute counts and putting together a map that better represents the impact COVID-19 is having across the country.",
		imageId: "https://cdn-images-1.medium.com/max/800/1*kOJIZb_kpQww-uSG6wlVUQ.png",
		readingTime: "5"
	},
	{
		id: "3",
		title: "Disease models & differential equations: connecting geographies with time series clustering",
		createdAt: "2020-05-02",
		link: "https://medium.com/topos-ai/disease-models-differential-equations-connecting-geographies-with-time-series-clustering-8f91d3545876?source=collection_home---4------3-----------------------",
		subtitle: "With this modified SIRD model in hand, we set about fitting it to county-level US COVID-19 data. Our goal is to develop a robust understanding of the characteristics of curves across counties (what counties saw gentler progressions, exponential growth, or sudden spikes).",
		imageId: "https://cdn-images-1.medium.com/max/800/1*CB9Tuz5kDZhzeVh6DDXzNQ.png",
		readingTime: "8"
	},
	{
		id: "4",
		title: "High COVID-19 Vulnerability Seen In and Near Navajo Nation and Hopi Reservation in Arizona",
		createdAt: "2020-04-30",
		link: "https://medium.com/topos-ai/high-covid-19-vulnerability-seen-in-and-near-navajo-nation-and-hopi-reservation-in-arizona-edba321699cb?source=collection_home---4------4-----------------------",
		subtitle: "High infection rates are cause for alarm anywhere, but demographic and hospital data from the counties overlapping the reservations reveal additional challenges for the Navajo Nation and the Hopi.",
		imageId: "https://cdn-images-1.medium.com/max/800/1*Qp5nc--0-vtxcUFVEoExsQ.png",
		readingTime: "3"
	},
	{
		id: "5",
		title: "Deriving hospital travel times with population-weighted sampling",
		createdAt: "2020-04-22",
		link: "https://medium.com/topos-ai/deriving-hospital-travel-times-with-population-weighted-sampling-db05c49dde79?source=---------5",
		subtitle: "To understand vulnerability through the lens of access to critical medical care, we generated a new feature, “median distance to the nearest hospital” for all counties in the U.S.",
		imageId: "https://cdn-images-1.medium.com/fit/t/1600/480/1*QQHAKDr0nhN3uD45Fw-wxg.png",
		readingTime: "6"
	},
	{
		id: "6",
		title: "What do the similarities and differences between places tell us about how COVID-19 is spreading?",
		createdAt: "2020-04-15",
		link: "https://medium.com/topos-ai/what-do-the-similarities-and-differences-between-places-tell-us-about-how-covid-19-is-spreading-86e9233a9e56",
		subtitle: "We briefly demonstrate how we can look at similarities between places to generate insights about how the pandemic is unfolding, without the use of a more complex predictive model.",
		imageId: "https://miro.medium.com/max/6720/1*Ca9W79OmGnfqJV06oeUVHA.png",
		readingTime: "7"
	},
	{
		id: "7",
		title: "Ugly Produce: Coming soon to your doorstep",
		createdAt: "2018-11-09",
		link: "https://medium.com/topos-ai/ugly-produce-coming-soon-to-your-doorstep-f72d4200ceee",
		subtitle: "How Topos’ transformative understanding of location is powering the strategic geographic expansion of Imperfect Produce.",
		imageId: "https://cdn-images-1.medium.com/max/2000/0*t2U1iC8hmFyf7tKo.jpg",
		readingTime: "6"
	},
	{
		id: "8",
		title: "On Dollar Slices, Pizza Vectors, Prosciutto Zones and Topping Hyperspace",
		createdAt: "2018-08-06",
		link: "https://medium.com/topos-ai/on-dollar-slices-pizza-vectors-prosciutto-zones-and-topping-hyperspace-f163e7ebbccf",
		subtitle: "Pizza in NYC is ubiquitous and highly variable, providing a fascinating lens on the culture of the city.",
		imageId: "https://cdn-images-1.medium.com/max/800/1*4oxPYBLYEjAFMJDHr-6PAg.png",
		readingTime: "13"
	},
	{
		id: "9",
		title: "Band on the Run: Connecting neighborhoods through live music",
		createdAt: "2018-02-26",
		link: "https://medium.com/topos-ai/band-on-the-run-connecting-neighborhoods-through-live-music-bd6e73c37437",
		subtitle: "In this article we use machine learning to explore the ways that neighborhoods are connected by live music.",
		imageId: "https://cdn-images-1.medium.com/max/800/1*Xt8iZO4uZ8iigTxQw_rYmg.gif",
		readingTime: "9"
	},
	{
		id: "10",
		title: "Introducing The Topos Similarity Index and [x] Everywhere",
		createdAt: "2017-11-16",
		link: "https://medium.com/topos-ai/introducing-the-topos-similarity-index-and-x-everywhere-82fcec1fb367",
		subtitle: "What makes one neighborhood similar to another?",
		imageId: "https://cdn-images-1.medium.com/max/800/1*fGwnQY_Ujh_n4lUXc2HQlA.png",
		readingTime: "3"
	},
	{
		id: "11",
		title: "The Next Wave: Predicting the future of coffee in New York City",
		createdAt: "2017-09-07",
		link: "https://medium.com/topos-ai/the-next-wave-predicting-the-future-of-coffee-in-new-york-city-23a0c5d62000",
		subtitle: "Using economics, urbanism, data, and AI, we formulated a model that predicts optimal coffee shop locations in New York City.",
		imageId: "https://cdn-images-1.medium.com/max/800/1*K0wyFSD3tHy8rDy5kd9caw.gif",
		readingTime: "12"
	},
	{
		id: "12",
		title: "Five Boroughs for the 21st Century",
		createdAt: "2017-06-19",
		link: "https://medium.com/topos-ai/five-boroughs-for-the-21st-century-8da941f53618",
		subtitle: "What happens when we abandon the century-old five borough partitioning of New York City and remap the city to reflect the realities of 2017.",
		imageId: "https://cdn-images-1.medium.com/max/800/1*C_-O_eFMUdRhXQNBPSMe4w.png",
		readingTime: "13"
	},
	{
		id: "13",
		title: "Rethinking ‘distance’ in New York City",
		createdAt: "2017-04-10",
		link: "https://medium.com/topos-ai/rethinking-distance-in-new-york-city-d17212d24919",
		subtitle: "At the heart of this article is a simple but profound question: what does distance mean in the 21st century?",
		imageId: "https://cdn-images-1.medium.com/max/800/1*RICvBPqd5u1WzB51JshFJA.png",
		readingTime: "8"
	}
]

export default mediumPosts
